// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-work-index-js": () => import("./../../../src/components/Work/index.js" /* webpackChunkName: "component---src-components-work-index-js" */),
  "component---src-pages-404-index-en-js": () => import("./../../../src/pages/404/index.en.js" /* webpackChunkName: "component---src-pages-404-index-en-js" */),
  "component---src-pages-404-index-es-js": () => import("./../../../src/pages/404/index.es.js" /* webpackChunkName: "component---src-pages-404-index-es-js" */),
  "component---src-pages-about-index-en-js": () => import("./../../../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-es-js": () => import("./../../../src/pages/about/index.es.js" /* webpackChunkName: "component---src-pages-about-index-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-photos-index-en-js": () => import("./../../../src/pages/photos/index.en.js" /* webpackChunkName: "component---src-pages-photos-index-en-js" */),
  "component---src-pages-photos-index-es-js": () => import("./../../../src/pages/photos/index.es.js" /* webpackChunkName: "component---src-pages-photos-index-es-js" */),
  "component---src-pages-works-index-en-js": () => import("./../../../src/pages/works/index.en.js" /* webpackChunkName: "component---src-pages-works-index-en-js" */),
  "component---src-pages-works-index-es-js": () => import("./../../../src/pages/works/index.es.js" /* webpackChunkName: "component---src-pages-works-index-es-js" */)
}

